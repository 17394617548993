<template>
  <div>
    <!-- RECURRENT INVOICE -->
    <div v-if="isRecurrentInvoice">
      <h3 class="mb-1">Toistuva viitenumero</h3>

      <!-- Show if not updating values  -->
      <div v-if="!updateServiceAccountId || !serviceAccountHasChanged">
        <p v-if="recurrentRefNum">
          {{ formatRefNum(recurrentRefNum) }}
        </p>

        <p v-if="currentRefNum">
          {{ formatRefNum(currentRefNum) }}
        </p>
      </div>

      <Warning-Component
        v-if="updateServiceAccountId && refWarningText"
        class="mt-1"
        marginBottom="8"
        >{{ refWarningText }}</Warning-Component
      >

      <!-- Show selecteted account -->
      <p><strong>Laskutustili: </strong> {{ recurrentServiceAccount }}</p>
    </div>

    <!-- OTHER INVOICES -->
    <div v-else>
      <h3 class="mb-1">Viitenumero</h3>

      <!-- Personal refnum in use -->
      <div v-if="personalRefNumInUse">
        <p class="success--text mb-1">Asiakaskohtainen viitenumero käytössä</p>

        <!-- Show when account needs to be selected first -->
        <div v-if="selectAccountFirst">
          <p v-if="!serviceAccountId && !personalRefNum" style="font-size: 14px">
            Valitse lomakkeelta tili, jolle lasku muodostetaan.
          </p>
          <p v-if="serviceAccountId && !personalRefNum" style="font-size: 14px">
            Asiakaskohtainen viitenumero muodostetaan laskun luonnin yhteydessä.
          </p>
          <!-- Show refNum -->
          <p v-if="serviceAccountId && personalRefNum">{{ personalRefNum }}</p>
        </div>

        <!-- Show when account is selected automatically -->
        <div v-else>
          <warning-component
            v-if="editMode && personalRefNum && personalRefNum !== currentRefNum"
            marginBottom="5"
          >
            Nykyinen laskun viitenumero {{ currentRefNum }} muutetaan tallennettaessa
            {{ formatRefNum(personalRefNum) }}
          </warning-component>

          <p v-else-if="!personalRefNum" style="font-size: 14px">
            Asiakaskohtainen viitenumero muodostetaan laskun luonnin yhteydessä.
          </p>
          <p v-else>{{ formatRefNum(personalRefNum) }}</p>

          <p v-if="automaticlySelectedServiceAccount" class="mt-1">
            <strong>Laskutustili: </strong> {{ automaticlySelectedServiceAccount }}
          </p>
        </div>
      </div>

      <!-- Personal refnum NOT in use -->
      <div v-else>
        <p class="error--text mb-1">Asiakaskohtainen viitenumero ei käytössä</p>
        <p style="font-size: 14px" v-if="!currentRefNum">
          Uusi viitenumero muodostetaan laskun lähetyksen yhteydessä.
        </p>
        <warning-component v-else marginBottom="5">
          Tallenna luonnos poistaaksesi nykyisen viitenumeron {{ formatRefNum(currentRefNum) }}.
          Uusi viitenumero muodostetaan laskun lähetyksen yhteydessä.
        </warning-component>
      </div>

      <div class="mt-1">
        <v-switch
          v-model="internalRefNumInUse"
          style="margin-top: 0px"
          :label="referenceNumberUsageChangeText"
          hide-details
          @change="changeTenantPersonalRefNumUsage"
          :loading="loading"
        ></v-switch>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from "@/mixins/mixins";
import invoiceMixins from "@/mixins/invoiceMixins";
import { mapActions } from "vuex";

export default {
  mixins: [mixins, invoiceMixins],

  props: {
    principalTenant: {
      type: Object,
      default: () => {
        return {};
      },
    },
    apartment: {
      type: Object,
      default: () => {
        return null;
      },
    },
    serviceAccountId: { type: String, default: null }, // cid in ropo
    selectAccountFirst: { type: Boolean, default: false },
    currentRefNum: { type: String, default: "" },
    recurrentRefNum: { type: String, default: "" },
    isRecurrentInvoice: { type: Boolean, default: false },
    selectedServiceAccountId: { type: String, default: null },
    updateServiceAccountId: { type: Boolean, default: false },
    originalServiceAccountId: { type: String, default: null },
  },

  data() {
    return {
      loading: false,
      internalRefNumInUse: false,
    };
  },

  mounted() {
    this.internalRefNumInUse = this.personalRefNumInUse;
  },

  computed: {
    editMode() {
      return this.$route.path.includes("/edit");
    },

    referenceNumberUsageChangeText() {
      if (this.personalRefNumInUse) {
        return `Poista ${this.principalTenantName} asiakaskohtainen viitenumero käytöstä`;
      } else {
        return `Ota ${this.principalTenantName} asiakaskohtainen viitenumero käyttöön`;
      }
    },

    personalRefNumInUse() {
      return this.principalTenant?.tenantId?.personalRefNumInUse || false;
    },

    principalTenantName() {
      return this.principalTenant?.tenantId?.name || "";
    },

    serviceAccounts() {
      return this.getServiceAccounts() || [];
    },

    refNumData() {
      return this.getTenantRefNumData(
        this.principalTenant,
        this.apartment,
        this.serviceAccountId,
        this.serviceAccounts,
        this.selectAccountFirst
      );
    },

    personalRefNum() {
      return this.refNumData?.referenceNumber || null;
    },

    automaticlySelectedServiceAccount() {
      if (this.serviceAccounts.length < 2) {
        return "Oletustili";
      } else {
        if (this.refNumData?.accountServiceId) {
          const account = this.getServiceAccountByServiceId(
            this.serviceAccounts,
            this.refNumData.accountServiceId
          );
          if (account) return account.name;
        }

        return null;
      }
    },

    recurrentServiceAccount() {
      let selectedServiceId = null;
      // When updating and selectedServiceAccountId is set, return that
      if (this.updateServiceAccountId && this.selectedServiceAccountId) {
        selectedServiceId = this.selectedServiceAccountId;
      }

      // If not updating, return the original service account id if it exists
      else if (this.originalServiceAccountId) {
        selectedServiceId = this.originalServiceAccountId;
      }

      if (selectedServiceId) {
        const account = this.getServiceAccountByServiceId(this.serviceAccounts, selectedServiceId);
        if (account) return account.name;
      }

      return "Oletustili";
    },

    serviceAccountHasChanged() {
      return this.originalServiceAccountId !== this.selectedServiceAccountId;
    },

    refWarningText() {
      if (this.serviceAccountHasChanged) {
        if (!this.originalServiceAccountId) {
          return "Viitenumero päivitetään seuraavaan toistuvaan laskuun nykyisen laskunsaajan mukaan. Mikäli lasku on muodostetu alunperin samalle laskunsaajalle ja laskutustilille, viitenumero ei muutu. Tallennuksen jälkeen näet uuden viitenumeron.";
        } else {
          return "Viitenumero päivitetään seuraavaan toistuvaan laskuun. Tallennuksen jälkeen näet uuden viitenumeron.";
        }
      }
      return null;
    },
  },

  watch: {
    serviceAccountHasChanged(val) {
      if (val) {
        this.showPopup("Huomioi muuttuva viitenumero", "info");
      }
    },

    personalRefNumInUse(val) {
      this.internalRefNumInUse = val;
    },
  },

  methods: {
    ...mapActions("tenant", ["switchTenantPersonalRefNumUsage"]),

    async changeTenantPersonalRefNumUsage() {
      try {
        this.loading = true;
        this.$nextTick(() => {
          this.internalRefNumInUse = this.personalRefNumInUse;
        });
        const tenantId = this.principalTenant?.tenantId?._id;
        await this.switchTenantPersonalRefNumUsage(tenantId);
        this.$emit("switchprincipaltenantpersonalrefnumuse");
        this.showPopup("Viitenumerokäsittely päivitetty", "success");
        this.loading = false;
      } catch (err) {
        this.showPopup(err, "error");
      } finally {
        this.$nextTick(() => {
          this.internalRefNumInUse = this.personalRefNumInUse;
          this.loading = false;
        });
      }
    },

    formatRefNum(refNum) {
      if (refNum) {
        const slice = 4;
        let chunks = [];
        for (let i = 0; i < refNum.length; i += slice) {
          const part = refNum.slice(i, i + slice);
          chunks.push(part);
        }
        return chunks.join(" ");
      } else {
        return null;
      }
    },
  },
};
</script>

<style scoped></style>
